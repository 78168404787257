import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import { AppModule } from './angularApp/app.module';
import { environment } from './angularApp/environments/environment';



(window as any).environment = environment;
(window as any).bootstrapNewAngular = function (iFrameId, url) {
  let newAngularIframe : HTMLIFrameElement = document.getElementById(iFrameId) as any;
  newAngularIframe.setAttribute("src", url);

  newAngularIframe.onload = () => {
    newAngularIframe.contentWindow.postMessage({"wingsJsInfo": sessionStorage.getItem('encodedAccessToken')}, '*');
  }

}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(()=>{
});