import { Env } from "@sade/ng-adfs";

export const environment = {
  production: false,
  serviceUrl: "https://www.apitestqa.jab7.wings.ford.com/WingsREST/api/",
  angularFrontend: "https://wings-frontend-qa-619078306104.us-central1.run.app/#/",
  envLabel: "Qa",
  adfsEnv: Env.QA,
  adfsResource: "urn:wings:pcf:weblogin:qa",
  adfsClientId: "urn:wings:pcf:native:qa",
  simulationUrl: "https://simulationqa.wings.ford.com/api/v1/"
};
