import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BootstrapingService } from "../../services/bootstraping.service";

@Component({
  templateUrl:"./wings-js.component.html",
  selector:"wings-angular"
})

export class WingsJsComponent implements AfterViewInit, OnInit{

  constructor(private bootstrapingService : BootstrapingService) {
  }
  ngOnInit(): void {
    this.bootstrapingService.bootstrapWings();    
  }

  ngAfterViewInit(): void {
  }
  
}