import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserIdService } from '@sade/ng-adfs';
import { UserInfo } from './objects/user-info';
import { AppModule } from './app.module';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  constructor( private user :UserIdService) {
  }
  ngOnInit(): void {
  
  }

  ngAfterViewInit(){
  }

  setUserInfoByToken(){
    let userInfoObj : UserInfo;
    let userInfo = sessionStorage.getItem("userInfo");
    if(userInfo){
      userInfoObj = JSON.parse(userInfo);
    }
    userInfoObj.email = this.user.getClaimFromToken("mail");
    userInfoObj.name = `${this.user.getClaimFromToken("givenName")} ${this.user.getClaimFromToken("sn")}`;
    userInfoObj.jobTitle = userInfoObj.jobTitle.slice(0,19);
    sessionStorage.setItem("userInfo",JSON.stringify(userInfoObj));
  }

}
