import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService, UrlConsumerService } from '@sade/ng-adfs';
import { AppComponent } from './app.component';
import { EmptyComponent } from './components/empty/empty.component';
import { WingsJsComponent } from './components/wingsJs/wings-js.component';


const routes: Routes = [
  {
    path:'',
    component: EmptyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'access_token',
    component: EmptyComponent,
    canActivate: [UrlConsumerService]
  },

];

@NgModule({
  imports: 
  [
    RouterModule.forRoot(routes,{
      useHash:true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
