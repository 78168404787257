import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpgradeModule } from '@angular/upgrade/static'
import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { AuthGuardService, Env, HttpRequestInterceptor, NgADFSModule, UrlConsumerService, UserIdService } from '@sade/ng-adfs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from './environments/environment';
import { EmptyComponent } from './components/empty/empty.component';
import { WingsJsComponent } from './components/wingsJs/wings-js.component';
import { BootstrapingService } from './services/bootstraping.service';

declare var angular: any;

class HybridUrlHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree) { 
    return url.toString().startsWith("/access_token") || url.toString() == "/" || url.toString().endsWith("/WingsAngular");
  }
  extract(url: UrlTree) { return url; }
  merge(url: UrlTree, whole: UrlTree) { return url; }
}

// angular.module('WingsUiAppModule')
//   .directive(
//     'ng2Demo',
//     downgradeComponent({component: AppComponent})
//   );

@NgModule({
  declarations: [
    AppComponent,
    WingsJsComponent,
    EmptyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UpgradeModule,
    NgADFSModule.forRoot({
      clientId: environment.adfsClientId,
      environment: environment.adfsEnv,
      resourceUri: environment.adfsResource
    }),
  ],
  providers: [
    { provide: UrlHandlingStrategy, useClass: HybridUrlHandlingStrategy }  ,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    UserIdService, 
    AuthGuardService, 
    UrlConsumerService,
    BootstrapingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
