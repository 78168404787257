import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthGuardService } from "@sade/ng-adfs";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BootstrapingService {
  isBootstraped : BehaviorSubject<boolean> = new BehaviorSubject(false);
  angular = window["angular"];
  constructor(private auth : AuthGuardService, private router : Router){

  }
  bootstrapWings(){
    const obs = new Observable((observable)=>{
      const inter = setInterval(()=>{
        let isExpired = this.auth.isTokenExpired()
        observable.next(isExpired);
        if(!isExpired){
          clearInterval(inter);
          observable.complete();
        }
      },300)
    })
    let subs = obs.subscribe((cantBootstrap)=>{
      console.log(cantBootstrap);
      if(!cantBootstrap){
        this.angular.bootstrap(document,["WingsUiAppModule"]);
        this.router.navigateByUrl("/WingsAngular/home");
        subs.unsubscribe();
      } 
    })
  }
} 